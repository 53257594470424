/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.card {
  padding: 0 !important;
  height: 100%;
}

.card.card-stats .card-header.card-header-icon,
.card.card-stats .card-header.card-header-text {
  text-align: right;
}

.card.card-stats .card-header i,
.card.card-stats .card-header fa-icon {
  font-size: 36px !important;
  line-height: 56px;
  width: 56px;
  height: 56px;
  text-align: center;
  margin-right: 0;
}

.card.card-stats .card-header fa-icon {
  font-size: 30px !important;
}

.card [class*=card-header-] {
  margin: 0 15px;
  padding: 0;
  position: relative;
}

.card [class*=card-header-] .card-icon,
.card [class*=card-header-] .card-text {
  border-radius: 3px;
  background-color: var(--fuse-primary);
  padding: 15px;
  margin-top: -20px;
  margin-right: 15px;
  float: left;
}

.card-icon {
  color: #eee;
}

.card.card-stats .card-header .card-icon + .card-category,
.card.card-stats .card-header .card-icon + .card-count {
  padding-top: 10px;
}

.card.card-stats .card-header .card-category:not([class*=text-]) {
  color: #999;
  font-size: 14px;
}

.card-count {
  font-size: 1.5625rem;
}

.card.card-stats .card-header + .card-footer {
  margin-top: 5px;
}

.card .card-body + .card-footer,
.card .card-footer {
  padding: 10px 0 0;
  margin: 0 15px 10px;
  border-radius: 0;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.card .card-body + .card-footer .stats,
.card .card-footer .stats {
  display: inline-flex;
}

.card .card-header-warning .card-icon,
.card .card-header-warning .card-text,
.card .card-header-warning:not(.card-header-icon):not(.card-header-text) {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(var(--fuse-primary-rgb), 0.4);
}

.mat-progress-bar {
  position: relative !important;
}

.analysis-title {
  padding-bottom: 18px;
  padding-top: 3px;
  font-weight: 400;
  font-size: 20px;
}

.content {
  padding: 25px;
  position: relative;
  width: 100%;
}
@screen sm {
  .content {
    padding: 35px;
  }
}

.page-title {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 18px;
}
@screen sm {
  .page-title {
    font-size: 2.3rem;
  }
}

.page-subtitle {
  font-weight: 500;
  font-size: 22px;
}

.page-header {
  margin-bottom: 10px;
}

.round-button {
  padding: 4px 28px !important;
  border-radius: 29px !important;
  font-size: 0.9rem;
}

.table-message {
  padding: 13px 12px;
  color: var(--fuse-text-default);
}
.table-message .mat-icon {
  vertical-align: middle;
}
.table-message span {
  vertical-align: middle;
  margin-left: 7px;
}

/* Table actions */
.actions {
  @apply flex items-center gap-3;
}

/* Trash */
.trash {
  position: relative;
  cursor: pointer;
  width: 11px;
  height: 12px;
  display: inline-block;
  margin-top: 5px;
  background: #949494;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  vertical-align: middle;
}
.trash i:after {
  content: "";
  /* background: black; */
  position: absolute;
  margin: auto;
  left: 0;
  right: -5px;
  bottom: 0;
  top: 0px;
  width: 2px;
  height: 10px;
}
.trash i::before {
  content: "";
  /* background: #000000; */
  position: absolute;
  left: 4px;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 2px;
  height: 10px;
}
.trash span {
  position: absolute;
  width: 16px;
  background: #949494;
  height: 3px;
  display: block;
  top: -4px;
  margin: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: -10px;
  right: -10px;
  transform: rotate(0deg);
  transition: transform 250ms;
  transform-origin: 19% 100%;
}
.trash span:before {
  content: "";
  position: absolute;
  width: 9px;
  background: #949494;
  height: 2px;
  display: block;
  top: -2px;
  margin: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  left: -10px;
  right: -10px;
}

.trash:hover {
  background-color: #d50000 !important;
}
.trash:hover span {
  transition: transform 250ms;
  transform: rotate(-29deg);
  background-color: #d50000 !important;
}
.trash:hover span:before {
  background-color: #d50000 !important;
}

[hidden] {
  display: none !important;
}

/* password validation list*/
.validation-list {
  width: 270px;
  border: solid 1px #ccc;
  border-radius: 5px;
  background: #fff;
  padding: 10px;
  margin: 0;
  z-index: 99;
}
.validation-list .verified {
  color: black;
}
.validation-list .verified .mat-icon {
  color: green;
}
.validation-list .unverified {
  color: gray;
}
.validation-list .unverified .mat-icon {
  color: #d4d4d4;
}

.validation-list > li {
  list-style-type: none;
  padding: 8px 0;
}
.validation-list > li .mat-icon {
  font-size: 23px;
  width: 23px;
  height: 21px;
  vertical-align: bottom;
  margin-right: 5px;
}

.validation-list > li:last-child {
  border-bottom: none;
}

textarea {
  resize: none !important;
}

.info-grid {
  @apply gap-x-3;
}
.info-grid .grid-content {
  columns: 2;
  gap: 0.5rem;
  justify-content: space-between;
  @apply py-1 text-lg;
}
.info-grid .grid-input-content {
  padding-bottom: 0 !important;
}
.info-grid .grid-content > div {
  overflow: auto;
  color: black;
}
.info-grid .grid-content > div:nth-child(1) {
  @apply font-medium text-lg;
}

.dark .grid-content > div {
  color: white !important;
}

.mdc-dialog__content .grid-content {
  @apply py-3;
}
.mdc-dialog__content .grid-content:not(:nth-last-child(-n+2)) {
  border-bottom: 1px solid var(--fuse-border);
}

.align-middle {
  vertical-align: middle;
}

input[type=radio][disabled] {
  opacity: 0;
}

.nav-header-footer {
  width: 100%;
  bottom: 15px;
  left: 25px;
  right: 0;
  align-items: flex-start;
  margin-bottom: 5px;
  padding: 15px;
  padding-top: 0;
}
.nav-header-footer h4,
.nav-header-footer a {
  font-weight: 300;
  margin-top: 6px;
  font-size: 12px;
}