/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */
/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
/* Quill */
@import '~quill/dist/quill.snow.css';
/* Material overrides */
/* -------------------------------------------------------------------------- */
/*  @ Table
/* -------------------------------------------------------------------------- */
th.mdc-data-table__header-cell {
  background: var(--fuse-primary) !important;
  color: white !important;
}

.mat-mdc-table-sticky.mat-mdc-cell {
  background: white !important;
}

.dark .mat-mdc-table-sticky.mat-mdc-cell {
  background: #1e293b !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Forms
/* -------------------------------------------------------------------------- */
.mat-mdc-form-field {
  width: 100%;
}

.mat-mdc-form-field-hint {
  flex: 1 !important;
}

.mat-mdc-form-field-hint-spacer {
  flex: 0 !important;
}

.mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-form-field-bottom-align::before {
  height: 25px;
  vertical-align: top;
}

.compact-control {
  --mdc-icon-button-icon-size: 22px;
}
.compact-control .mat-mdc-form-field-infix {
  min-height: 42px !important;
}
.compact-control .mat-mdc-form-field-subscript-wrapper {
  height: 0;
}
.compact-control .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 9px;
  padding-bottom: 9px;
}

.filter-control {
  --mdc-typography-body1-font-size: 0.8rem !important;
  --mdc-icon-button-icon-size: 20px !important;
}
.filter-control.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 18px;
}
.filter-control .mat-mdc-form-field-infix {
  min-height: 35px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.filter-control mat-label {
  font-size: 0.85rem;
}
.filter-control .mdc-floating-label--float-above {
  transform: translateY(-25.75px) scale(0.75) !important;
}

.table-control {
  position: relative;
}
.table-control .mat-mdc-form-field-subscript-wrapper {
  position: absolute;
  top: 100%;
  font-size: var(--mdc-typography-caption-font-size, 0.55rem);
}
.table-control .mat-mdc-form-field-hint-wrapper,
.table-control .mat-mdc-form-field-error-wrapper {
  padding: 0;
  white-space: nowrap;
}

/* -------------------------------------------------------------------------- */
/*  @ Dialog
/* -------------------------------------------------------------------------- */
.mat-mdc-dialog-content {
  padding-left: 0 !important;
  padding-right: 2px !important;
}

.mat-mdc-dialog-title {
  padding-left: 0 !important;
  padding-bottom: 1rem !important;
  @apply text-2xl font-medium !important;
}

.mdc-dialog__content {
  padding-top: 10px !important;
  overflow-x: hidden !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Tabs
/* -------------------------------------------------------------------------- */
.mat-mdc-tab-header .mat-mdc-tab-label-container {
  box-shadow: none !important;
}

.mat-mdc-tab,
.mdc-tab__ripple::before,
.mat-mdc-tab-ripple {
  border-radius: 34px !important;
}

.mdc-tab--active {
  opacity: 1 !important;
  @apply bg-primary !important;
}
.mdc-tab--active .mdc-tab__text-label {
  color: white !important;
}
.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab--active .mdc-tab__icon {
  transition-delay: 0ms !important;
}

.mdc-tab-indicator {
  display: none !important;
}

.mat-mdc-tab-body-content {
  padding: 0 !important;
}

@screen sm {
  .mat-mdc-tab-body-content {
    padding: 24px !important;
  }
}
/* -------------------------------------------------------------------------- */
/*  @ Slide toggle
/* -------------------------------------------------------------------------- */
.mdc-switch {
  --mdc-switch-selected-handle-color: green;
  --mdc-switch-selected-focus-handle-color: green;
  --mdc-switch-selected-hover-handle-color: green;
  --mdc-switch-selected-pressed-handle-color: green;
  --mdc-switch-selected-track-color: #acdfac;
  --mdc-switch-selected-hover-track-color: #acdfac;
  --mdc-switch-selected-focus-track-color: #acdfac;
  --mdc-switch-selected-pressed-track-color: #acdfac;
}

.mdc-switch__handle::after {
  border: none !important;
}

.dark .mdc-switch {
  --mdc-switch-selected-track-color: #629a62;
  --mdc-switch-selected-hover-track-color: #629a62;
  --mdc-switch-selected-focus-track-color: #629a62;
  --mdc-switch-selected-pressed-track-color: #629a62;
}
.dark .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  --mdc-switch-selected-icon-color: white ;
}

/* -------------------------------------------------------------------------- */
/*  @ Select search
/* -------------------------------------------------------------------------- */
.mat-datepicker-content {
  box-shadow: none !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Card
/* -------------------------------------------------------------------------- */
.mat-mdc-card-content:first-child {
  padding-top: 30px !important;
}

.mat-mdc-card-content {
  padding: 0 28px !important;
}

.mat-mdc-card-footer {
  padding: 12px;
}

.mat-mdc-card-header-text {
  width: 100%;
}

/* -------------------------------------------------------------------------- */
/*  @ Stepper
/* -------------------------------------------------------------------------- */
.mat-step-header {
  border-radius: 40px !important;
}

.mat-horizontal-stepper-header {
  height: 50px !important;
}

.mat-horizontal-stepper-wrapper {
  gap: 0.5rem;
}

/* -------------------------------------------------------------------------- */
/*  @ Expansion panel
/* -------------------------------------------------------------------------- */
.mat-expansion-indicator::after {
  padding: 4px !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]) {
  background: none !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Paginators
/* -------------------------------------------------------------------------- */
.compact-paginator .mat-mdc-paginator-container {
  min-height: 26px !important;
}
.compact-paginator .mat-mdc-paginator-container .mat-mdc-icon-button.mat-mdc-button-base {
  height: 25px !important;
  width: 25px !important;
  min-height: 0;
}
.compact-paginator .mat-mdc-paginator-container {
  padding: 0 8px !important;
}

/* -------------------------------------------------------------------------- */
/*  @ Datepicker
/* -------------------------------------------------------------------------- */
.month-picker .mat-calendar-period-button {
  pointer-events: none;
}
.month-picker .mat-calendar-period-button .mat-calendar-arrow {
  display: none;
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
  display: flex;
  align-items: center;
}

ngx-mat-datetime-content .mat-stroked-button {
  background: green;
  border: none;
  color: white;
}

/* -------------------------------------------------------------------------- */
/*  @ Radio
/* -------------------------------------------------------------------------- */
.mat-mdc-radio-button .mdc-form-field > label {
  white-space: nowrap;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item.center {
  z-index: 5;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/* Swal */
.dark .skeleton-loader {
  background: #26354f no-repeat !important;
}
.dark .skeleton-loader.progress:before {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(69, 103, 126, 0.6), rgba(255, 255, 255, 0)) !important;
}
.dark .swal2-popup {
  background-color: #19191a !important;
}
.dark .swal2-popup .swal2-title,
.dark .swal2-popup .swal2-html-container {
  color: #e1e1e1 !important;
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-cancel {
  color: black !important;
}

.delete-button:focus {
  box-shadow: none !important;
}

/* Toast */
.welcome-toast {
  background-color: #009add !important;
  top: 57px;
  width: max-content !important;
}
.welcome-toast .toast-title {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 4px;
}

.dark .ngx-toastr,
.ngx-toastr-dark {
  box-shadow: 0 0 12px #1d1d1d !important;
}

.process-toaster {
  background-color: #393939 !important;
  width: max-content !important;
  padding-left: 15px !important;
}

/* Owl */
.dark .owl-dots .owl-dot span {
  background: #869791 !important;
}
.dark .owl-dots .owl-dot.active span {
  background: #D6D6D6 !important;
}